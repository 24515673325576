.AnimeListOuterContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #b2ebf2;
  display: flex;
  justify-content: center;
}

.ElementTitleNative {
  color: #263238;
  font-size: 25px;
}

.ElementTitleRomaji {
  color: #263238;
  font-size: 11px;
}

.progressText {
  margin-top: 5px;
  color: #263238;
}

.scoreText {
  margin-top: 5px;
  color: #263238;
}

.progressButton {
  width: 25px;
  height: 25px;
  border-style: none;
  margin-left: 2px;
}

.AnimeListElement {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.CoverImage {
  height: 200px;
}

.ElementInfoContainer {
  margin-left: 10px;
}

.ElementProgressContainer {
  margin-top: 20px;
}
