.HeaderContainer {
  /* Contains the AniTekiLogo and AvatarUsernameContainer */
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #b2ebf2;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.genericText {
  color: #263238;
}

.UsernameANI {
  font-size: 20px;
  color: black;
}

.AvatarUsernameContainer {
  /* Contains the avatar and username */
  overflow: auto;
  align-content: center;
}
