.input {
  border-width: 0px 0px 1px;
  border-color: black;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #323a47;
  padding-bottom: 5px;
  background-color: transparent;
  width: auto;
  display: inline-block;
  right: left;
}
